import React from "react";

export const HamburgerMenu = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.625" height="18" viewBox="0 0 20.625 18">
    <g id="hamburger-menu" transform="translate(-4.688 -5.625)">
        <path id="Path_14" data-name="Path 14" d="M4.688,5.625H25.313V7.5H4.688Z"/>
        <path id="Path_15" data-name="Path 15" d="M4.688,14.063H25.313v1.875H4.688Z" transform="translate(0 -0.375)"/>
        <path id="Path_16" data-name="Path 16" d="M4.688,22.5H25.313v1.875H4.688Z" transform="translate(0 -0.75)"/>
    </g>
</svg>
)