import React, { useEffect, useState } from "react";

export const RampApplicationChallenge = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [flag, setFlag] = useState('');
    const [displayText, setDisplayText] = useState('');
    const [currIndex, setCurrIndex] = useState(0);

    useEffect(() => {
        fetch('https://wgg522pwivhvi5gqsn675gth3q0otdja.lambda-url.us-east-1.on.aws/637265')
            .then(response => {
                if(response.ok){
                    return response.body;
                }
                throw response;
            })
            .then(async data => {
                const reader = data.getReader();
                const { value } = await reader.read();
                const decoder = new TextDecoder('utf-8');
                setFlag(decoder.decode(value));
            })
            .catch(() => {
                console.error("There was an error fetching the flag");
                setFlag("There was an error fetching the flag");
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [])


    useEffect(() => {
        if(currIndex < flag.length){
            setTimeout(() => {
                setDisplayText(prevText => prevText + flag[currIndex]);
                setCurrIndex(prevIndex => prevIndex + 1);
            }, 500)
        }
    }, [currIndex, flag])

    return (
        <>
            {isLoading
            ? <div>Loading...</div>
            : <ul>
                {displayText.split('').map(item => <li key={item}>{item}</li>)}
            </ul>}
        </>
    )

}