import './App.css';
import { Booking } from './components/booking';
import { Hero } from './components/hero';
import { Navbar } from './components/navbar';

function DogServices() {
  return (
    <>
    {/* <Navbar/> */}
    <Hero/>
    <Booking/>
    </>
  );
}

export default DogServices;
